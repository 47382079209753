import {
  enCustomToolRequestFormLink,
  ruCustomToolRequestFormLink,
} from './config';

import type { ToolInfo } from './store/store';

export type normalizedToolInfo = {
  id: number;
  operation_id: string;
  name: string;
  description: string;
  link: string;
  is_dev: boolean;
  is_free?: boolean;
  tags: number[];
};

export const normalizeToolData = (tool: ToolInfo): normalizedToolInfo => {
  const linkRegex = /<a\s+(?:[^>]*?\s+)?href=["']([^"']*)["']/;

  const description = tool.description
    .replace(/<[^>]*>/g, '')
    .trim()
    .replace(/Подробнее$|Подробнее\.$|Details$|More info$/, '')
    .trim();

  const linkMatch = tool.description.match(linkRegex);
  const link = linkMatch && linkMatch.length > 1 ? linkMatch[1] : '';

  return { ...tool, description, link };
};

export const countDecimals = function (val: number) {
  if (Math.floor(val.valueOf()) === val.valueOf()) return 0;
  return val.toString().split('.')[1].length || 0;
};

export const getFormLink = (locale: string) => {
  if (locale === 'ru') {
    return ruCustomToolRequestFormLink;
  } else {
    return enCustomToolRequestFormLink;
  }
};
