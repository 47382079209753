const YANDEX_COUNTER_ID = window.toolboxConfig.yandex_metrika_id;
const GOOGLE_COUNTER_ID = window.toolboxConfig.google_analytics_id;

export const sendYmEvent = (event) => {
  if (window?.ym && YANDEX_COUNTER_ID) {
    window.ym(YANDEX_COUNTER_ID, 'reachGoal', event);
  }
};

export const initYandexMetrics = () => {
  if (!window?.ym && !YANDEX_COUNTER_ID) {
    console.log(
      'Could not initialize yandex metrics, identifiers are not defiened',
    );
    return;
  }

  (function (m, e, t, r, i, k, a) {
    m[i] =
      m[i] ||
      function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
    m[i].l = 1 * new Date();
    for (var j = 0; j < document.scripts.length; j++) {
      if (document.scripts[j].src === r) {
        return;
      }
    }
    (k = e.createElement(t)),
      (a = e.getElementsByTagName(t)[0]),
      (k.async = 1),
      (k.src = r);
    a.parentNode.insertBefore(k, a);
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

  window.ym(YANDEX_COUNTER_ID, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  });
};

export const initGoogleAnalytics = () => {
  if (!GOOGLE_COUNTER_ID) {
    console.log(
      'Could not initialize google analytics, identifiers are not defiened',
    );
    return;
  }

  (function () {
    var script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_COUNTER_ID}`;

    script.onload = function () {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', GOOGLE_COUNTER_ID);
    };

    document.head.appendChild(script);
  })();
};
